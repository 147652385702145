/* eslint-disable prettier/prettier */
import { HttpResponse } from '../../utils/HttpFetch';
import { ActionsStatus, SelectOption, StateError } from '../types';

export type Indicators = {
  variable: string,
  variableOld: string,
  bloco: string,
  direction: string,
  denominator: string,
  format: string,
  shortLabel: string,
  decPlaces: string,
  viz: string,
  vizReg: string,
  vizUf: string,
  bloqAnos: string,
  bloqViz: string,
  abreviacoes: string,
  id: string,
  nomeIndicadores: string,
  AbreviaEs: string,
  Version: number,
};

export type CityVisualization = {
  idMunic7: string,
  nomemun: string,
  estadoAbrev: string,
  ano: string,
  noRegiao: string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhmRenda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
  Version: number,
};

export type CountryVisualization = {
  id:string,
  ano: string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhm_renda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
};

export type StateVisualization = {
  id:string,
  ano: string,
  id_estado: string,
  estadoAbrev: string,
  estado:string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhm_renda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
};

export type RegionVisualization = {
  id:string,
  ano: string,
  noRegiao:string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhm_renda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
};

export type MacroRegionVisualization = {
  ano: string,
  macrorregiao:string,
  noMacro:string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhm_renda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
};

export type DataToDispersion = {
  id: string,
  category: string,
  local: string,
  microRegion: string,
  x: number,
  y: number,
};

export type MapRankingResponse = {
  idMunic7: string,
  nomemun: string,
  noMacro: string,
  macrorregiao: string,
  noRegiao: string,
  regiao: string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhm_renda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
};

export type CirclePackingResponse = {
  noMacro: string,
  ano: string,
  noRegiao: string,
  estadoAbrev: string,
  nomemun: string,
  regiao: string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhmRenda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:string,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
}

export type DataToMapRanking = {
  idMunic7: string,
  nomeMun: string,
  value: string,
}

export type LocalToMapRanking = {
  id: string,
  label: string,
  value: number,
}

export type CityQuery = {
  ano: string,
  idMunic7: string,
  codmun: string,
  nomemun: string,
  estadoAbrev: string,
  regiao: string,
  macrorregiao: string,
};

export type StateQuery = {
  idEstado: string,
  estado: string,
  estadoAbrev: string,
  ano: string,
};

export type CirclePackinNode = {
  macroRegion: string,
  name: string,
  population: number
  region: string,
  state: string,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhmRenda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
}

export type CirclePackinGroup = {
  category: number,
  name: string,
  totalPopulation: number,
  fraction: number,
  children: CirclePackinNode[]
}

export type CirclePackingData = {
  name: string,
  children: CirclePackinGroup[]
}

export type TimeLineSeries = {
  year: number,
  cobEsf:number,
  cobAb:number,
  cobAcs:number,
  cobVacBcg:number,
  cobVacRota:number,
  cobVacMenin:number,
  cobVacPneumo:number,
  cobVacPolio:number,
  cobVacTvd1:number,
  cobVacPenta: number,
  cobVacHepb: number,
  cobVacHepa: number,
  pctPrenatalAdeq: number,
  pctPrenatalZero:number,
  pctPrenatal1a6:number,
  pctPrenatal7m:number,
  txMortInfIbge: number,
  nObitos:number,
  nObitosCsap:number,
  nObitosEvit:number,
  nObitosMaldef:number,
  txMort:number,
  txMortAjOms:number,
  txMortAjCens:number,
  txMortCsap:number,
  txMortCsapAjOms:number,
  txMortCsapAjCens:number,
  txMortEvit:number,
  txMortEvitAjOms:number,
  txMortEvitAjCens:number,
  pctMortMaldef:number,
  nHosp:number,
  nHospCsap:number,
  txHosp:number,
  txHospCsap:number,
  nMed:number,
  nEnf:number,
  nMedCh:number,
  nEnfCh:number,
  nLeitoutiSus:number,
  nLeitoSus:number,
  txMed:number,
  txEnf:number,
  txMedCh:number,
  txEnfCh:number,
  txLeitoutiSus:number,
  txLeitoSus:number,
  nLeitoutiNsus:number,
  nLeitoNsus:number,
  txLeitoutiNsus:number,
  txLeitoNsus:number,
  cobPriv:number,
  despTotSaudePcMun:number,
  pctDespRecpSaudeMun:number,
  despRecpSaudePcMun:number,
  despTotSaudePcMunDef:number,
  despRecpSaudePcMunDef:number,
  ideb5ano:number,
  ideb9ano:number,
  numFamiliasBf:number,
  gastoPbfPcDef:number,
  expVida:number,
  idhm:number,
  idhmEduc:number,
  idhmLong:number,
  idhmRenda:number,
  pctSanAdeq:number,
  pctRural:number,
  pibCtePc:number,
  rendaDomPc:number,
  pop:number,
  pop0a4:number,
  pop5a9:number,
  pop10a14:number,
  pop15a19:number,
  pop20a24:number,
  pop25a29:number,
  pop30a34:number,
  pop35a39:number,
  pop40a44:number,
  pop45a49:number,
  pop50a54:number,
  pop55a59:number,
  pop60a64:number,
  pop65a69:number,
  pop70a74:number,
  pop75a79:number,
  pop80m:number,
  pctPop0a4:number,
  pctPop5a9:number,
  pctPop10a14:number,
  pctPop15a19:number,
  pctPop20a24:number,
  pctPop25a29:number,
  pctPop30a34:number,
  pctPop35a39:number,
  pctPop40a44:number,
  pctPop45a49:number,
  pctPop50a54:number,
  pctPop55a59:number,
  pctPop60a64:number,
  pctPop65a69:number,
  pctPop70a74:number,
  pctPop75a79:number,
  pctPop80m:number,
  pctPop65m:number,
  pctPop75m:number,
  pctPop5a14:number,
  pctPop15a64:number,
  popFem:number,
  pop0a4Fem:number,
  pop5a9Fem:number,
  pop10a14Fem:number,
  pop15a19Fem:number,
  pop20a24Fem:number,
  pop25a29Fem:number,
  pop30a34Fem:number,
  pop35a39Fem:number,
  pop40a44Fem:number,
  pop45a49Fem:number,
  pop50a54Fem:number,
  pop55a59Fem:number,
  pop60a64Fem:number,
  pop65a69Fem:number,
  pop70a74Fem:number,
  pop75a79Fem:number,
  pop80mFem:number,
  pctPop0a4Fem:number,
  pctPop5a9Fem:number,
  pctPop10a14Fem:number,
  pctPop15a19Fem:number,
  pctPop20a24Fem:number,
  pctPop25a29Fem:number,
  pctPop30a34Fem:number,
  pctPop35a39Fem:number,
  pctPop40a44Fem:number,
  pctPop45a49Fem:number,
  pctPop50a54Fem:number,
  pctPop55a59Fem:number,
  pctPop60a64Fem:number,
  pctPop65a69Fem:number,
  pctPop70a74Fem:number,
  pctPop75a79Fem:number,
  pctPop80mFem:number,
  pctPop10a49Fem:number,
  popMasc:number,
  pop0a4Masc:number,
  pop5a9Masc:number,
  pop10a14Masc:number,
  pop15a19Masc:number,
  pop20a24Masc:number,
  pop25a29Masc:number,
  pop30a34Masc:number,
  pop35a39Masc:number,
  pop40a44Masc:number,
  pop45a49Masc:number,
  pop50a54Masc:number,
  pop55a59Masc:number,
  pop60a64Masc:number,
  pop65a69Masc:number,
  pop70a74Masc:number,
  pop75a79Masc:number,
  pop80mMasc:number,
  pctPop0a4Masc:number,
  pctPop5a9Masc:number,
  pctPop10a14Masc:number,
  pctPop15a19Masc:number,
  pctPop20a24Masc:number,
  pctPop25a29Masc:number,
  pctPop30a34Masc:number,
  pctPop35a39Masc:number,
  pctPop40a44Masc:number,
  pctPop45a49Masc:number,
  pctPop50a54Masc:number,
  pctPop55a59Masc:number,
  pctPop60a64Masc:number,
  pctPop65a69Masc:number,
  pctPop70a74Masc:number,
  pctPop75a79Masc:number,
  pctPop80mMasc:number,
  txPopmf:number,
  pctPopFem:number,
  pctPopMasc:number,
}

export type DataToTimeLine = {
  country: {
    series: TimeLineSeries[],
    name: string
  },
  state: {
    series: TimeLineSeries[],
    name: string
  },
  city: {
    series: TimeLineSeries[],
    name: string
  },
  region: {
    series: TimeLineSeries[],
    name: string,
  },
  macro_region: {
    series: TimeLineSeries[],
    name: string
  },
  yMin: number,
  yMax: number,
  minYear: number,
  maxYear: number
}


export type VisualizationState = {
  error: StateError,
  indicators?: SelectOption[],
  firstIndicators?: SelectOption[],
  secondIndicators?: SelectOption[],
  timeLineIndicators?: SelectOption[],
  dataToMapRanking?: DataToMapRanking[],
  dataToCirclePacking?: CirclePackingData,
  dataToTimeLine?: DataToTimeLine,
  city?: DataToDispersion | DataToTimeLine,
  cityOptions: SelectOption[],
  stateOptions?: SelectOption[],
  country?: DataToTimeLine | LocalToMapRanking,
  countryDispersion: DataToDispersion,
  state?: DataToTimeLine | LocalToMapRanking,
  stateDispersion?: DataToDispersion,
  region?: DataToDispersion | DataToTimeLine,
  macroRegion?: DataToDispersion | DataToTimeLine,
  cities?: DataToDispersion[] | DataToTimeLine[],
  actionsStatus: ActionsStatus & { isLoadingUfMapData?: boolean },
};

export const scope = 'visualizations';

export const GET_VISUALIZATIONS_REQUEST = `@${scope}:GET_VISUALIZATIONS_REQUEST`;
export const GET_VISUALIZATIONS_SUCCESS = `@${scope}:GET_VISUALIZATIONS_SUCCESS`;
export const GET_VISUALIZATIONS_FAILURE = `@${scope}:GET_VISUALIZATIONS_FAILURE`;
export const GET_INDICATORS_SUCCESS = `@${scope}:GET_INDICATORS_SUCCESS`;
export const GET_STATES_SUCCESS = `@${scope}:GET_STATES_SUCCESS`;
export const GET_DISPERSION_DATA_SUCCESS = `@${scope}:GET_DISPERSION_DATA_SUCCESS`;
export const GET_TIME_LINE_DATA_SUCCESS = `@${scope}:GET_TIME_LINE_DATA_SUCCESS`;
export const GET_PACKING_DATA_SUCCESS = `@${scope}:GET_PACKING_DATA_SUCCESS`;
export const GET_MAP_RANKING_DATA_SUCCESS = `@${scope}:GET_MAP_RANKING_DATA_SUCCESS`;
export const GET_FIRST_INDICATORS_SUCCESS = `@${scope}:GET_FIRST_INDICATORS_SUCCESS`;
export const GET_SECOND_INDICATORS_SUCCESS = `@${scope}:GET_SECOND_INDICATORS_SUCCESS`;
export const GET_TIME_LINE_INDICATORS_SUCCESS = `@${scope}:GET_TIME_LINE_INDICATORS_SUCCESS`;
export const CLEAR_CONTEXT = `@${scope}:CLEAR_CONTEXT`;


export const GET_CITIES_REQUEST = `@${scope}:GET_CITIES_REQUEST`;
export const GET_CITIES_SUCCESS = `@${scope}:GET_CITIES_SUCCESS`;
export const GET_CITIES_FAILURE = `@${scope}:GET_CITIES_FAILURE`;

export interface GetCitiesActionRequest {
  type: typeof GET_CITIES_REQUEST;
}

export interface GetCitiesActionSuccess {
  type: typeof GET_CITIES_SUCCESS;
  payload: HttpResponse<CityQuery>;
}

export interface GetStatesActionSuccess {
  type: typeof GET_STATES_SUCCESS;
  payload: HttpResponse<StateQuery>;
}

export interface GetDataToMapRankingSuccess {
  type: typeof GET_MAP_RANKING_DATA_SUCCESS;
  payload: {
    data: HttpResponse<MapRankingResponse>,
    state: HttpResponse<StateVisualization>,
    country: HttpResponse<CountryVisualization>,
    indicator: string,
    granularity: string,
  };
}

export interface GetDataToPackingSuccess {
  type: typeof GET_PACKING_DATA_SUCCESS;
  payload: {
    data: HttpResponse<CirclePackingResponse>,
    indicator: string,
  };
}

export interface GetCitiesActionFailure {
  type: typeof GET_CITIES_FAILURE;
  payload: number;
}

export interface GetVisualizationsActionRequest {
  type: typeof GET_VISUALIZATIONS_REQUEST;
}

export interface GetVisualizationsActionSuccess {
  type: typeof GET_VISUALIZATIONS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface GetFirstIndicatorsToDispersionSuccess {
  type: typeof GET_FIRST_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface GetDataToTimeLineSuccess {
  type: typeof GET_TIME_LINE_DATA_SUCCESS;
  payload: {
    city: HttpResponse<CityVisualization>,
    country: HttpResponse<CountryVisualization>,
    state: HttpResponse<StateVisualization>,
    region: HttpResponse<RegionVisualization>,
    macroRegion: HttpResponse<MacroRegionVisualization>,
    indicator: string,
  };
}

export interface GetSecondIndicatorsToDispersionSuccess {
  type: typeof GET_SECOND_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface GetIndicatorsToTimeLineSuccess {
  type: typeof GET_TIME_LINE_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface GetIndicatorsToVisualizationSuccess {
  type: typeof GET_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface getDispersionDataSuccess {
  type: typeof GET_DISPERSION_DATA_SUCCESS;
  payload: {
    city: HttpResponse<CityVisualization>,
    country: HttpResponse<CountryVisualization>,
    state: HttpResponse<StateVisualization>,
    region: HttpResponse<RegionVisualization>,
    macroRegion: HttpResponse<MacroRegionVisualization>,
    cities: HttpResponse<CityVisualization>,
    firstIndicator: string,
    secondIndicator: string,
  };
}

export interface getFirstIndicatorsSuccess {
  type: typeof GET_FIRST_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface getSecondIndicatorsSuccess {
  type: typeof GET_SECOND_INDICATORS_SUCCESS;
  payload: {
    indicators: HttpResponse<Indicators>,
  };
}

export interface GetVisualizationsActionFailure {
  type: typeof GET_VISUALIZATIONS_FAILURE;
  payload: number;
}

export interface ClearContextAction {
  type: typeof CLEAR_CONTEXT;
}

export type VisualizationsActionTypes =
  | GetVisualizationsActionRequest
  | GetVisualizationsActionSuccess
  | GetVisualizationsActionFailure
  | GetIndicatorsToVisualizationSuccess
  | GetStatesActionSuccess
  | GetDataToPackingSuccess
  | getDispersionDataSuccess
  | getFirstIndicatorsSuccess
  | getSecondIndicatorsSuccess
  | GetIndicatorsToTimeLineSuccess
  | GetDataToTimeLineSuccess;
